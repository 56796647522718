import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import './styles.scss';

type AnchorLinkProps = {
  className?: string;
  children: ReactNode;
  route: string;
};

const AnchorLink = ({
  className = '',
  children,
  route,
}: AnchorLinkProps): JSX.Element => {
  return (
    <Link className={`link ${className}`} to={route}>
      {children}
    </Link>
  );
};

export default AnchorLink;
