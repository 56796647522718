import React, {ReactNode} from 'react';
import {Flex} from '@fluentui/react-northstar';
import warningIcon from '../../resources/img/icons/warning.svg';
import './styles.scss';

type AlertType = 'error'; // TODO: add more types in the future

type AlertMessageProps = {
  className?: string;
  children: ReactNode;
  type?: AlertType;
};

const AlertMessage = ({
  type = 'error',
  children,
  className,
}: AlertMessageProps): JSX.Element | null => {
  return children ? (
    <div className={`alert ${type} ${className ?? ''}`}>
      <Flex>
        <Flex>
          <img
            alt={`Warning icon`}
            className={'leading-icon'}
            src={warningIcon}
          />
        </Flex>
        <Flex className={'message-container'} vAlign={'center'}>
          {children}
        </Flex>
      </Flex>
    </div>
  ) : null;
};

export default AlertMessage;
