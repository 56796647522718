import {amplitude as amp} from '@edume/bento';
import {amplitude} from './index';
import {decodeTeamsJwtToken} from '../utilities';
import AuthService from '../services/authService';

import {VIEWED_LANDING_PAGE, CLICKED_SIGNIN} from './trackingProps';
const {VIEWPORT_HEIGHT, VIEWPORT_WIDTH, TENANT_ID, MS_TEAMS_COMPANY_NAME} =
  amp.ampProperties;

export const setUserViewportSize = () => {
  if (window) {
    amplitude.setAmplitudeUserProps({
      [VIEWPORT_WIDTH]: window.innerWidth,
      [VIEWPORT_HEIGHT]: window.innerHeight,
    });
  }
};

export const setNewUserProps = async (ssoToken) => {
  const token = decodeTeamsJwtToken(ssoToken);
  const amplitudeUserProps = {
    [TENANT_ID]: token.tid,
  };

  setUserViewportSize();
  amplitude.setAmplitudeUserId(token.oid);
  amplitude.setAmplitudeUserProps(amplitudeUserProps);
};

export const sendViewedLandingPageEvent = () =>
  amplitude.logAmplitudeEvent(VIEWED_LANDING_PAGE);

export const sendClickedSignupEvent = async (accessToken) => {
  const user = await AuthService.getUserProfile(accessToken);
  return amplitude.logAmplitudeEvent(CLICKED_SIGNIN, {
    [MS_TEAMS_COMPANY_NAME]: user.companyName || 'null',
  });
};
