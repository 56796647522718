import React from 'react';
import AppConfig from '../../app-config';
import eduMeLogoImage from '../../resources/img/edume-logo.svg';

const LogoImageSection = (): JSX.Element => {
  return (
    <div className={`text-centre logo-image-container`}>
      <img alt={`${AppConfig.appName} Logo`} src={eduMeLogoImage} />
    </div>
  );
};

export default LogoImageSection;
