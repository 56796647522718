import {TeamsFx} from '@microsoft/teamsfx';
import {TeamsJwtToken} from './types';
import jwt_decode from 'jwt-decode';

let teamsfx: TeamsFx | null = null;
// make the instance singleton
export const getTeamsFxClient = (): TeamsFx => {
  if (!teamsfx) {
    teamsfx = new TeamsFx();
  }

  return teamsfx;
};

export const decodeTeamsJwtToken = (token: string): TeamsJwtToken => {
  return jwt_decode<TeamsJwtToken>(token);
};

export const isDeviceOsApp = (): boolean => {
  if (navigator && navigator.userAgent) {
    return navigator.userAgent.toLowerCase().includes(`teams`);
  } else {
    return false;
  }
};
