import AppConfig from '../../app-config';
import {getTeamsFxClient} from '../../utilities';

export const useMsalAuth = () => {
  const getAccessTokenSilently = async (
    arePermissionsGranted = false
  ): Promise<string | null> => {
    try {
      const credentials = await getTeamsFxClient().getCredential();
      const accessToken = await credentials.getToken(
        arePermissionsGranted
          ? AppConfig.permissionScopes
          : AppConfig.basicPermissionScopes
      );
      return accessToken?.token ? accessToken.token : null;
    } catch (e) {
      return null;
    }
  };

  // return access token when login successful
  const login = async (
    arePermissionsGranted = false
  ): Promise<string | null> => {
    try {
      await getTeamsFxClient().login(
        arePermissionsGranted
          ? AppConfig.permissionScopes
          : AppConfig.basicPermissionScopes
      );
      return await getAccessTokenSilently(arePermissionsGranted);
    } catch (e) {
      return null;
    }
  };

  return {
    getAccessTokenSilently,
    login,
  };
};
