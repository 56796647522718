import {ApiError} from '../types';
import HttpService from './httpService';
import AppConfig from '../app-config';

class PermissionService {
  public static checkIfUserIsGlobalAdmin = async (
    ssoToken: string | null
  ): Promise<boolean | ApiError> => {
    const result = await HttpService.get<{
      isGlobalAdmin: boolean;
    }>(AppConfig.apiEndpoints.permissions.checkIsGlobalAdmin, {
      Authorization: ssoToken || '',
    });
    if (result instanceof ApiError) {
      return result;
    }

    return result.data.isGlobalAdmin ? true : false;
  };

  public static checkIfPermissionsGrantedForOrganisation = async (
    ssoToken: string
  ): Promise<boolean | ApiError> => {
    const result = await HttpService.get<{
      permissionsGranted: boolean;
    }>(AppConfig.apiEndpoints.permissions.checkState, {
      Authorization: ssoToken,
    });
    if (result instanceof ApiError) {
      return result;
    }

    return result.data.permissionsGranted ? true : false;
  };

  public static grantPermissions = async (
    accessToken: string
  ): Promise<null | ApiError> => {
    const result = await HttpService.put(
      AppConfig.apiEndpoints.permissions.grant,
      {},
      {
        Authorization: accessToken,
      }
    );

    return result instanceof ApiError ? result : null;
  };
}

export default PermissionService;
