import React, {ReactNode} from 'react';
import {Flex, Segment} from '@fluentui/react-northstar';
import {LoadingIndicator} from '@edume/magnificent';
import './styles.scss';

type BoxContentProps = {
  isLoading?: boolean;
  className?: string;
  children: ReactNode;
  minWidth?: string;
  maxWidth?: string;
};

const BoxContent = (props: BoxContentProps): JSX.Element => {
  return (
    <Flex
      className={`full-height`}
      column
      gap='gap.small'
      hAlign='center'
      vAlign='center'
    >
      {props.isLoading ? (
        <LoadingIndicator size='normal' containerStyle='centerFlex' hexagons />
      ) : (
        <Segment
          styles={{
            minWidth: props.minWidth ?? `500px`,
            maxWidth: props.maxWidth ?? `500px`,
          }}
          className={`content-box ${props.className ?? ''}`}
          content={props.children}
        />
      )}
    </Flex>
  );
};

export default BoxContent;
