import AppConfig from '../app-config';

type StorageData<T> = {
  data: T;
  expiresAtInSeconds: number | null;
};

class StorageService {
  /**
   * @param duration - lifespan in seconds. If the value is not supplied, the data will live as long as the storage exists
   */
  public static storeData = <T>(
    key: string,
    data: T,
    duration: number = 0
  ): void => {
    if (!data) {
      return;
    }
    let expiresAt: number | null = null;
    if (duration > 0) {
      expiresAt = duration + new Date().getTime() / 1000;
    }
    const storageData: StorageData<T> = {
      data,
      expiresAtInSeconds: expiresAt ? expiresAt : null,
    };

    sessionStorage.setItem(
      `${AppConfig.storageKeyPrefix}_${key}`,
      JSON.stringify(storageData)
    );
  };

  public static retrieveData = <T>(key: string): T | null => {
    const storedDataString = sessionStorage.getItem(
      `${AppConfig.storageKeyPrefix}_${key}`
    );
    if (!storedDataString) {
      return null;
    }
    const storageData: StorageData<T> = JSON.parse(storedDataString);
    if (!storageData.expiresAtInSeconds) {
      return storageData.data;
    }
    const expiresAtInMilliseconds = storageData.expiresAtInSeconds * 1000;
    const currentTimeInMilliseconds = new Date().getTime();

    if (expiresAtInMilliseconds > currentTimeInMilliseconds) {
      return storageData.data;
    } else {
      // remove the data since it is already expired
      sessionStorage.removeItem(`${AppConfig.storageKeyPrefix}_${key}`);

      return null;
    }
  };

  public static clear = (key: string) => {
    sessionStorage.removeItem(`${AppConfig.storageKeyPrefix}_${key}`);
  };
}

export default StorageService;
