import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Button} from '@fluentui/react-northstar';
import {useLogin} from '../../hooks/useLogin';
import AppConfig from '../../app-config';
import routes from '../../routes';
import BoxContent from '../../components/BoxContent';
import AnchorLink from '../../components/AnchorLink';
import LogoImageSection from '../../components/BoxContent/LogoImageSection';
import microsoftLogoImage from '../../resources/img/ms-logo.svg';
import './styles.scss';

type LocationProps = {
  state: {
    arePermissionsGranted?: boolean;
  };
};

const Login = (): JSX.Element => {
  const {handleLoginButtonClick, isLoading, checkIfUserIsLoggedIn} = useLogin();
  const location: LocationProps = useLocation();

  useEffect(() => {
    checkIfUserIsLoggedIn(location.state?.arePermissionsGranted ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BoxContent isLoading={isLoading} className={'login-content'}>
      <LogoImageSection />
      <div className={`body`}>
        <h2 className={'title'}>Welcome to {AppConfig.appName}!</h2>
        <p className={'primary-description text-centre'}>
          To get started we need a few details.
        </p>
        <div className={'text-centre'}>
          <Button
            onClick={handleLoginButtonClick}
            flat
            className={'microsoft-login-button'}
            icon={<img src={microsoftLogoImage} alt={'Microsoft Logo'} />}
            content={`Sign in with Microsoft`}
          />
        </div>
        <div className={'text-centre actions'}>
          <AnchorLink route={routes.privacy}>Privacy</AnchorLink>
        </div>
      </div>
    </BoxContent>
  );
};

export default Login;
