import React from 'react';
import {useUnauthorizedCourseAccess} from '../../hooks/useUnauthorizedCourseAccess';
import ErrorInfo from '../../components/ErrorInfo';

const UnauthorizedCourseAccess = (): JSX.Element => {
  const {handleLoginDsClick} = useUnauthorizedCourseAccess();

  return (
    <ErrorInfo
      title={`You can’t access this course as an Admin`}
      message={`This course is currently only available to Learners`}
      buttonText={`Go back to the admin panel`}
      handleRetryButtonClick={handleLoginDsClick}
      extraInfo={`For more information, please speak to your Administrator or email`}
    />
  );
};

export default UnauthorizedCourseAccess;
