import React, {useEffect} from 'react';
import {Button} from '@edume/magnificent';
import {useOrganisationPermissions} from '../../hooks/useOrganisationPermissions';
import BoxContent from '../../components/BoxContent';
import AppConfig from '../../app-config';
import AlertMessage from '../../components/AlertMessage';
import eduMeLogoImage from '../../resources/img/edume-logo.svg';
import './styles.scss';

const OrganisationPermissions = (): JSX.Element => {
  const organisationPermissionsHook = useOrganisationPermissions();

  useEffect(() => {
    organisationPermissionsHook.registerAdminConsentWindowNotifiers();
    organisationPermissionsHook.initiateSsoTokenRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={`page-container`}>
      <BoxContent
        isLoading={
          organisationPermissionsHook.isLoading ||
          (!organisationPermissionsHook.ssoToken &&
            !organisationPermissionsHook.errorMessage)
        }
        minWidth={`400px`}
        maxWidth={`480px`}
        className={'admin-consent-content'}
      >
        <div className={'text-centre logo-image-container'}>
          <img alt={`${AppConfig.appName} Logo`} src={eduMeLogoImage} />
        </div>
        <AlertMessage className={'admin-consent-error'} type={'error'}>
          {organisationPermissionsHook.errorMessage}
        </AlertMessage>
        <div className={'body'}>
          <h2 className={'title'}>Grant permissions</h2>
          <p className={'primary-description'}>
            In order for your users to have a smooth experience, we require you
            to grant a few permissions. This will only need to happen once.
          </p>
          <Button
            disabled={
              organisationPermissionsHook.ssoToken == null ||
              organisationPermissionsHook.isLoading
            }
            fullWidth={true}
            type='primary'
            onClick={
              organisationPermissionsHook.handleOpenAdminConsentWindowClick
            }
            noMarginTop={true}
            noMarginLeft={true}
          >
            Continue
          </Button>
        </div>
      </BoxContent>
    </div>
  );
};

export default OrganisationPermissions;
