export class HttpResult<T> {
  constructor(readonly statusCode: number, readonly data: T) {}
}

export type HttpVerb = `GET` | `PUT` | `POST` | `DELETE`;

export class ApiError {
  constructor(
    public statusCode: number,
    public message: string,
    public errorCode: string | null = null
  ) {}
} // TODO: add field to handle validation error messages in the future

export type HttpHeaders = {
  Authorization: string;
};

export enum PermissionsConsentFeedback {
  Granted = `AdminConsentGranted`,
  Required = `AdminConsentRequired`,
  RequiredAgain = `AdminConsentRequiredAgain`,
  CancelledByUser = `CancelledByUser`,
  FailedToOpenWindow = `FailedToOpenWindow`,
}

// TODO: add more fields as needed
// for both SSO and Access Token
export type TeamsJwtToken = {
  aud: string; // application/ client ID for SSO token
  tid: string; // tenant ID
};

/**
 * TODO: create anther error type to handle form validations if needed.
 */
export type ApiGenericErrorResponse = {
  statusCode: number;
  error: string;
  message: string;
  errorCode?: string;
};

export enum ErrorCode {
  INTEGRATION_NOT_FOUND = `integration_not_found`,
}
