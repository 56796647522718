import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {authentication, initialize, getContext} from '@microsoft/teams-js';
import {useErrorHandler} from 'react-error-boundary';
import {useMsalAuth} from './shared/useMsalAuth';
import PermissionService from '../services/permissionService';
import {
  ApiError,
  ErrorCode,
  PermissionsConsentFeedback,
  TeamsJwtToken,
} from '../types';
import AppConfig from '../app-config';
import {decodeTeamsJwtToken, isDeviceOsApp} from '../utilities';
import routes from '../routes';
import StorageService from '../services/storageService';

const desktopAppConsentFeedbackKey = `permissions_consent_feedback`;
export const useOrganisationPermissions = () => {
  const {getAccessTokenSilently, login} = useMsalAuth();
  const handleError = useErrorHandler();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [ssoToken, setSsoToken] = useState<TeamsJwtToken | null>(null);

  const getAdminConsentUrl = (tenantId: string, clientId: string): string => {
    const scope = AppConfig.permissionScopes
      .map((permission) => {
        return `https://graph.microsoft.com/${permission}`;
      })
      .join(' ');

    return `https://login.microsoftonline.com/${tenantId}/v2.0/adminconsent
        ?client_id=${clientId}
        &scope=${scope}
        &redirect_uri=${window.location.origin}/index.html
        &state=12345`;
  };

  const getModuleId = (): Promise<number | null> => {
    return new Promise((resolve, reject) => {
      try {
        getContext((context) => {
          resolve(context.subEntityId ? Number(context.subEntityId) : null);
        });
      } catch (e: any) {
        handleError(new Error(`Error checking module id: ${e.message}.`));
        reject(e);
      }
    });
  };

  const ssoTokenRequestSuccessCallback = async (ssoToken: string) => {
    setSsoToken(decodeTeamsJwtToken(ssoToken));
    const arePermissionsGranted =
      await PermissionService.checkIfPermissionsGrantedForOrganisation(
        ssoToken
      );

    if (arePermissionsGranted instanceof ApiError) {
      if (arePermissionsGranted.errorCode === ErrorCode.INTEGRATION_NOT_FOUND) {
        history.push({
          pathname: routes.signUp,
          state: {ssoToken},
        });
        return;
      } else {
        handleError(
          Error(
            `Unable to check permission status: ${arePermissionsGranted.message}`
          )
        );
      }
      return;
    }

    let accessToken = await getAccessTokenSilently(arePermissionsGranted);
    if (!accessToken) {
      history.push({pathname: routes.login, state: {arePermissionsGranted}});
      return;
    }
    const isGlobalAdmin = await PermissionService.checkIfUserIsGlobalAdmin(
      accessToken
    );
    if (!arePermissionsGranted && isGlobalAdmin) {
      history.push(routes.grantPermissions);
    } else {
      const moduleId = await getModuleId();
      if (moduleId) {
        StorageService.storeData<number>(
          AppConfig.storageKeys.moduleId,
          moduleId,
          60 * 5 // max 5 mins
        );
      }
      history.push({pathname: routes.login, state: {arePermissionsGranted}});
    }
    setIsLoading(false);
  };

  const ssoTokenRequestErrorCallback = async (error: string) => {
    handleError(Error(`Unable to generate the SSO token: ${error}`));
    setIsLoading(false);
  };

  const initiateSsoTokenRequest = async () => {
    setIsLoading(true);
    initialize();

    const authOptions: authentication.AuthTokenRequest = {
      successCallback: ssoTokenRequestSuccessCallback,
      failureCallback: ssoTokenRequestErrorCallback,
    };

    authentication.getAuthToken(authOptions);
  };

  const adminConsentRequestSuccessCallback = async (
    consentFeedback: string | undefined
  ) => {
    if (PermissionsConsentFeedback.Granted === consentFeedback) {
      let accessToken = await getAccessTokenSilently(true);
      if (!accessToken) {
        accessToken = await login();
        if (!accessToken) {
          handleError(`Unable to retrieve access token.`);
          return;
        }
      }
      const arePermissionsGranted = await PermissionService.grantPermissions(
        accessToken
      );
      if (arePermissionsGranted instanceof ApiError) {
        setErrorMessage('Something has gone wrong. Please try again.');
      } else {
        history.push(routes.login);
      }
    } else {
      handleError(Error(`Something has gone wrong. Please try again.`));
    }
    setIsLoading(false);
  };

  const adminConsentRequestClosedCallback = (reason: string | undefined) => {
    if (isDeviceOsApp()) {
      // on Mac/ Desktop version of app, it still invokes error call as we are using window.close to close the consent window
      const consentFeedback = localStorage.getItem(
        desktopAppConsentFeedbackKey
      );
      if (consentFeedback) {
        localStorage.removeItem(desktopAppConsentFeedbackKey);
        if (consentFeedback === PermissionsConsentFeedback.Granted) {
          adminConsentRequestSuccessCallback(consentFeedback);
          return;
        } else {
          reason = consentFeedback;
        }
      }
    }

    switch (reason) {
      case PermissionsConsentFeedback.FailedToOpenWindow: {
        setErrorMessage(
          `Unable to open popup? Please, ensure that popup is enabled for the browser.`
        );
        break;
      }
      case PermissionsConsentFeedback.RequiredAgain: {
        setErrorMessage(`Permissions need to be granted again.`);
        break;
      }
      default: {
        setErrorMessage(
          `You must grant permissions for your organisation in order to access ${AppConfig.appName}`
        );
        break;
      }
    }
    setIsLoading(false);
  };

  const openAdminConsentWindow = async (tenantId: string, clientId: string) => {
    authentication.authenticate({
      url: getAdminConsentUrl(tenantId, clientId),
      width: 600,
      height: 535,
      successCallback: adminConsentRequestSuccessCallback,
      failureCallback: adminConsentRequestClosedCallback,
    });
  };

  /**
   * For the  Desktop/ Mac Teams application, the Web Browser consent window does not close back itself as the admin consent permissions are supposed to be handled
   * within the Teams admin panel. So that we need to explicitly write the logic to handle events for Desktop/ Mac applications
   */
  const handleDeviceOsAppAdminConsentWindowEvents = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let adminConsent = urlParams.get('admin_consent');
    if (adminConsent === 'True' || adminConsent === 'False') {
      if (adminConsent === 'True') {
        if (urlParams.get('error') === `consent_required`) {
          localStorage.setItem(
            desktopAppConsentFeedbackKey,
            PermissionsConsentFeedback.RequiredAgain
          );
        } else {
          localStorage.setItem(
            desktopAppConsentFeedbackKey,
            PermissionsConsentFeedback.Granted
          );
        }
      } else {
        localStorage.setItem(
          desktopAppConsentFeedbackKey,
          PermissionsConsentFeedback.Required
        );
      }
      window.close();
    }
  };

  const handleWebAdminConsentWindowEvents = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let adminConsent = urlParams.get('admin_consent');
    if (adminConsent === 'True' || adminConsent === 'False') {
      if (adminConsent === 'True') {
        if (urlParams.get('error') === `consent_required`) {
          // can be true and error is present :(
          authentication.notifyFailure(
            PermissionsConsentFeedback.RequiredAgain
          );
        } else {
          authentication.notifySuccess(PermissionsConsentFeedback.Granted);
        }
      } else {
        authentication.notifyFailure(PermissionsConsentFeedback.Required);
      }
    }
  };

  const registerAdminConsentWindowNotifiers = () => {
    if (isDeviceOsApp()) {
      handleDeviceOsAppAdminConsentWindowEvents();
    } else {
      handleWebAdminConsentWindowEvents();
    }
  };

  const handleOpenAdminConsentWindowClick = () => {
    setErrorMessage(null);
    if (!ssoToken) {
      return;
    }

    openAdminConsentWindow(ssoToken.tid, AppConfig.clientId);
  };

  return {
    initiateSsoTokenRequest,
    errorMessage,
    isLoading,
    ssoToken,
    handleOpenAdminConsentWindowClick,
    registerAdminConsentWindowNotifiers,
  };
};
