import React, {ReactNode} from 'react';
import {Heading, Button, Link} from '@edume/magnificent';
import AppConfig from '../../app-config';
import noConnectionImage from '../../resources/img/no_connection.svg';
import './styles.scss';

type ErrorInfoProps = {
  title?: ReactNode;
  message?: ReactNode;
  errorMessage?: ReactNode;
  buttonText?: string;
  handleRetryButtonClick?: () => void;
  extraInfo?: string;
};

const ErrorInfo = ({
  title,
  message,
  errorMessage,
  buttonText,
  handleRetryButtonClick,
  extraInfo,
}: ErrorInfoProps): JSX.Element => {
  const renderEmailLink = () => (
    <Link
      linkSize='mini'
      linkColour='primary'
      text={AppConfig.supportEmail}
      href={`mailto:${AppConfig.supportEmail}`}
    />
  );

  const handleRetryButtonClickDefault = () => {
    window.location.reload();
  };

  return (
    <div className={`content-wrapper`}>
      <div className={`content`}>
        <img
          alt={`No Connection`}
          src={noConnectionImage}
          className={`no-connection`}
        />
        <div>
          <Heading weight={`regular`} size={`small`}>
            {title ?? `Something went wrong`}
          </Heading>
        </div>
        <div className={`message-textarea`}>
          <Heading size={`micro`}>
            {message ??
              `We could not load your content. Use the button to try again.`}
          </Heading>
        </div>
        <div className={`retry-button`}>
          <Button
            onClick={() => {
              handleRetryButtonClick
                ? handleRetryButtonClick()
                : handleRetryButtonClickDefault();
            }}
            type={`primary`}
          >
            {buttonText ?? `Retry`}
          </Button>
        </div>
        <div className={`extra-info`}>
          <Heading size={`mini`} colour={`greyDark`}>
            {extraInfo ??
              `If this problem continues, please speak to your Administrator or
            email`}
            {renderEmailLink()}
          </Heading>
        </div>
        {errorMessage ? (
          <Heading size={`mini`} colour={`greyDark`}>
            {`Error: ${errorMessage}`}
          </Heading>
        ) : null}
      </div>
    </div>
  );
};

export default ErrorInfo;
