import React, {Provider, teamsTheme} from '@fluentui/react-northstar';
import {HashRouter as Router, Redirect, Route} from 'react-router-dom';
import {useTeamsFx} from '@microsoft/teamsfx-react';
import {LoadingIndicator} from '@edume/magnificent';
import {ErrorBoundary} from 'react-error-boundary';
import {TeamsFxContext} from './Context';
import routes from './routes';
import Privacy from './containers/Privacy';
import TermsOfUse from './containers/TermsOfUse';
import TabConfig from './containers/TabConfig';
import ErrorInfo from './components/ErrorInfo';
import Login from './containers/Login';
import SignUp from './containers/SignUp';
import OrganisationPermissions from './containers/OrganisationPermissions';
import ContactCsTeam from './containers/ContactCsTeam';
import UnauthorizedCourseAccess from './containers/UnauthorizedCourseAccess';
import CompleteSetup from './containers/CompleteSetup';

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
const App = () => {
  const {loading, theme, themeString, teamsfx} = useTeamsFx();

  const renderRoutes = () => {
    return (
      <Router>
        <Route exact path='/'>
          <Redirect to={routes.tab} />
        </Route>
        {loading ? (
          <LoadingIndicator
            size='normal'
            containerStyle='centerFlex'
            hexagons
          />
        ) : (
          <>
            <Route exact path={routes.privacy} component={Privacy} />
            <Route exact path={routes.terms} component={TermsOfUse} />
            <Route
              exact
              path={routes.grantPermissions}
              component={OrganisationPermissions}
            />
            <Route
              exact
              path={routes.tab}
              component={OrganisationPermissions}
            />
            <Route
              exact
              path={routes.unauthorizedCourseAccess}
              component={UnauthorizedCourseAccess}
            />
            <Route exact path={routes.tabConfig} component={TabConfig} />
            <Route exact path={routes.login} component={Login} />
            <Route exact path={routes.signUp} component={SignUp} />
            <Route
              exact
              path={routes.completeSetup}
              component={CompleteSetup}
            />
            <Route
              exact
              path={routes.contactCsTeam}
              component={ContactCsTeam}
            />
          </>
        )}
      </Router>
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={({error}) => (
        <ErrorInfo errorMessage={error.message} />
      )}
    >
      <TeamsFxContext.Provider value={{theme, themeString, teamsfx}}>
        <Provider className={'ms-teams-theme-wrapper'} theme={teamsTheme}>
          {renderRoutes()}
        </Provider>
      </TeamsFxContext.Provider>
    </ErrorBoundary>
  );
};

export default App;
