import React, {useEffect} from 'react';
import {Button} from '@fluentui/react-northstar';
import {Heading, ExternalLinkIcon} from '@edume/magnificent';
import {useLogin} from '../../hooks/useLogin';
import {useLocation} from 'react-router-dom';

import './style.scss';
import microsoftLogoImage from '../../resources/img/ms-logo.svg';
import CustomerLogos from '../../resources/img/customer-logos.png';
import plgScreens from '../../resources/img/plg-screens.png';
import AppConfig from '../../app-config';

import {
  setNewUserProps,
  sendViewedLandingPageEvent,
} from '../../tracking/trackingFunctions';

type LocationProps = {
  state: {
    ssoToken?: string;
  };
};

const SignUp = (): JSX.Element => {
  const {handleSignUpButtonClick} = useLogin();
  const location: LocationProps = useLocation();
  useEffect(() => {
    setNewUserProps(location.state.ssoToken);
    sendViewedLandingPageEvent();
  }, []);

  return (
    <div className={'container'}>
      <div className={'body'}>
        <div className={'left-container'}>
          <Heading size={'xlarge'} weight={'bold'}>
            Try eduMe for free, from the tools you already use
          </Heading>
          <Heading size={'micro'}>
            Get started with eduMe’s intuitive, industry leading training
            platform for free all with Microsoft Teams. Simply sign in Microsoft
            Teams below.
          </Heading>
          <Heading size={'micro'}>
            <div className={'list'}>
              <span>✔️ Two week free trial</span>
              <span>✔️ Instant access, pending permissions</span>
              <span>✔️ No credit card required</span>
            </div>
          </Heading>
          <Button
            onClick={handleSignUpButtonClick}
            flat
            className={'microsoft-login-button'}
            icon={<img src={microsoftLogoImage} alt={'Microsoft Logo'} />}
            content={`Sign in with Microsoft`}
          />
          <Heading size={'mini'} colour='textGrey'>
            By submitting this form, you are agreeing to eduMe’s{' '}
            <a
              className={`link-text bold`}
              href={`${AppConfig.customerContactPageUrl}`}
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
              <ExternalLinkIcon size='tiny' colour='text' />
            </a>{' '}
            and{' '}
            <a
              className={`link-text bold`}
              href={`${AppConfig.termsOfServiceUrl}`}
              target='_blank'
              rel='noreferrer'
            >
              Terms of Service
              <ExternalLinkIcon size='tiny' colour='text' />
            </a>
            {'.'}
          </Heading>
        </div>
        <img className={'plg-screens'} src={plgScreens} alt='' />
      </div>
      <Heading size={'micro'}>Trusted by leading brands</Heading>
      <img src={CustomerLogos} alt='' />
    </div>
  );
};

export default SignUp;
