import React from 'react';
import {ExternalLinkIcon} from '@edume/magnificent';
import AppConfig from '../../app-config';
import BoxContent from '../../components/BoxContent';
import LogoImageSection from '../../components/BoxContent/LogoImageSection';
import './style.scss';

const CompleteSetup = (): JSX.Element => (
  <div className={'page-container'}>
    <BoxContent
      minWidth={`400px`}
      maxWidth={`480px`}
      className={`complete-setup`}
    >
      <LogoImageSection />
      <div className={'body'}>
        <h2 className={'primary-text text-centre title'}>
          Already using eduMe?
        </h2>
        <p className={`primary-description`}>
          If you are an eduMe admin, complete setup in 2 steps:
        </p>
        <ol className={`primary-description`}>
          <li>
            Enter your Tenant ID in{' '}
            <a
              className={`link bold`}
              href={`${AppConfig.dsBaseUrl}/settings/integrations/msTeams`}
              target='_blank'
              rel='noreferrer'
            >
              Integration Settings
              <ExternalLinkIcon size='tiny' colour='brandPrimary' />
            </a>
            {'.'}
          </li>
          <li>
            Grant permissions in Microsoft Teams - this will need to be done by
            a global admin.
          </li>
        </ol>
        <p className={`primary-description second-primary-description`}>
          If you need additional support, contact your eduMe Customer Success
          Manager or email{' '}
          <a
            className={`link bold`}
            target='_blank'
            href={`mailto:${AppConfig.supportEmail}`}
            rel='noreferrer'
          >
            {AppConfig.supportEmail}
          </a>
          {'.'} You can read more about eduMe and Microsoft Teams in our{' '}
          <a
            className={`link bold`}
            href={`${AppConfig.customerContactPageUrl}`}
            target='_blank'
            rel='noreferrer'
          >
            Help Centre
            <ExternalLinkIcon size='tiny' colour='brandPrimary' />
          </a>
          {'.'}
        </p>
      </div>
    </BoxContent>
  </div>
);

export default CompleteSetup;
