import {useMsalAuth} from './shared/useMsalAuth';
import AppConfig from '../app-config';

export const useUnauthorizedCourseAccess = () => {
  const {getAccessTokenSilently} = useMsalAuth();

  const handleLoginDsClick = async () => {
    let accessToken = await getAccessTokenSilently(true);
    if (accessToken) {
      window.location.assign(
        `${AppConfig.dsBaseUrl}/msTeams?token=${accessToken}`
      );
    }
  };

  return {
    handleLoginDsClick,
  };
};
