class AppConfig {
  public static appName: string = `eduMe`;
  public static clientId: string = process.env.REACT_APP_CLIENT_ID ?? ``;
  public static tenantId: string = process.env.REACT_APP_TENANT_ID ?? ``;
  public static apiBaseurl: string = process.env.REACT_APP_API_ROOT ?? ``;
  public static apiKey: string = process.env.REACT_APP_BACKEND_API_KEY ?? ``;
  public static amplitudeApiKey: string =
    process.env.REACT_APP_AMPLITUDE_API_KEY ?? '';
  public static basicPermissionScopes: string[] = process.env
    .REACT_APP_BASIC_PERMISSIONS
    ? process.env.REACT_APP_BASIC_PERMISSIONS.split(',')
    : ['User.Read', 'profile', 'openid', 'offline_access'];
  public static permissionScopes: string[] = process.env
    .REACT_APP_REQUIRED_PERMISSIONS
    ? process.env.REACT_APP_REQUIRED_PERMISSIONS.split(',')
    : [
        ...this.basicPermissionScopes,
        `Team.ReadBasic.All`,
        `Group.Read.All`,
        `Directory.Read.All`,
      ];
  public static lfeBaseUrl: string = process.env.REACT_APP_LFE_ROOT ?? ``;
  public static dsBaseUrl: string = process.env.REACT_APP_DS_ROOT ?? ``;
  public static supportEmail: string =
    process.env.REACT_APP_SUPPORT_EMAIL ?? `support@edume.com`;
  public static customerContactPageUrl: string =
    process.env.REACT_CUSTOMER_CONTACT_PAGE_URL ??
    `https://www.edume.com/integrations/microsoft-teams?utm_campaign=Microsoft%20Teams%20App%20Store&utm_source=Microsoft%20Teams%20App%20Store`;
  public static privacyPolicyUrl: string =
    process.env.REACT_APP_PRIVACY_POLICY_URL ??
    `https://www.edume.com/privacy-policy`;
  public static termsOfServiceUrl: string =
    process.env.REACT_APP_TERMS_OF_SERVICE_URL ?? `https://www.edume.com/terms`;
  public static storageKeyPrefix =
    process.env.REACT_APP_STORAGE_KEY_PREFIX ?? ``;
  public static graphBaseUrl =
    process.env.REACT_APP_GRAPH_BASE_URL ?? `https://graph.microsoft.com/v1.0`;
  public static graphUserEndpoint =
    process.env.REACT_APP_GRAPH_USER_ENDPOINT ??
    `me?$select=companyName,mail,displayName,givenName,surname,id,mobilePhone,jobTitle`;
  // add more key if we need to store more data in the future.
  public static storageKeys = {
    moduleId: `module_id`,
  };

  public static apiEndpoints = {
    permissions: {
      checkIsGlobalAdmin: `/msTeams/isGlobalAdmin`,
      checkState: `/msTeams/permissionsState`,
      grant: `/msTeams/permissionsState`,
    },
    user: {
      checkType: `/msTeams/userType`,
    },
    trial: {
      signUp: `/msTeams/trial`,
    },
  };
}

export default AppConfig;
