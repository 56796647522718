import React from 'react';
import {Button} from '@edume/magnificent';
import AppConfig from '../../app-config';
import BoxContent from '../../components/BoxContent';
import LogoImageSection from '../../components/BoxContent/LogoImageSection';
import './style.scss';

const ContactCsTeam = (): JSX.Element => (
  <div className={'page-container'}>
    <BoxContent
      minWidth={`400px`}
      maxWidth={`480px`}
      className={`contact-cs-content`}
    >
      <LogoImageSection />
      <div className={'body'}>
        <h2 className={'primary-text text-centre title'}>
          Create and deliver relevant training within Microsoft Teams
        </h2>
        <p className={`primary-description`}>
          Users can increase their knowledge in the flow of work, in the place
          where they're most productive.
        </p>
        <p className={`primary-description second-primary-description`}>
          You'll need an eduMe license to use this app – contact us via our
          website to get started.
        </p>
        <Button
          fullWidth={true}
          type='primary'
          onClick={() => {
            window.open(AppConfig.customerContactPageUrl, '_blank')?.focus();
          }}
        >
          Find out more at edume.com
        </Button>
        <p className={`secondary-description`}>
          Already using eduMe? Speak to your eduMe Customer Success Manager or
          email{' '}
          <a className={`link`} href={`mailto:${AppConfig.supportEmail}`}>
            {AppConfig.supportEmail}
          </a>{' '}
          to get set up.
        </p>
      </div>
    </BoxContent>
  </div>
);

export default ContactCsTeam;
