import axios from 'axios';
import {
  ApiError,
  ApiGenericErrorResponse,
  HttpHeaders,
  HttpResult,
  HttpVerb,
} from '../types';
import AppConfig from '../app-config';

class HttpService {
  public static get = async <T>(
    path: string,
    headers: HttpHeaders | null = null
  ): Promise<HttpResult<T> | ApiError> => {
    return HttpService.send(`GET`, path, null, headers);
  };

  public static post = async <T>(
    path: string,
    data: null | object,
    headers: HttpHeaders | null = null
  ): Promise<HttpResult<T> | ApiError> => {
    return HttpService.send(`POST`, path, data, headers);
  };

  public static put = async <T>(
    path: string,
    data: null | object,
    headers: HttpHeaders | null = null
  ): Promise<HttpResult<T> | ApiError> => {
    return HttpService.send(`PUT`, path, data, headers);
  };

  public static delete = async <T>(
    path: string,
    headers: HttpHeaders | null = null
  ): Promise<HttpResult<T> | ApiError> => {
    return HttpService.send(`DELETE`, path, null, headers);
  };

  private static send = async <T>(
    verb: HttpVerb,
    path: string,
    data: object | null,
    headers: HttpHeaders | null = null
  ): Promise<HttpResult<T> | ApiError> => {
    let response;
    let result: HttpResult<T>;
    try {
      const requestHeaders: {
        [`x-api-key`]: string;
        Authorization?: string;
      } = {
        [`x-api-key`]: AppConfig.apiKey,
      };
      if (headers && headers.Authorization) {
        requestHeaders.Authorization = `Bearer ${headers.Authorization}`;
      }
      const fullPath = `${AppConfig.apiBaseurl}${path}`;
      switch (verb) {
        case `PUT`: {
          response = await axios.put(fullPath, data, {
            headers: requestHeaders,
          });
          break;
        }
        case `DELETE`: {
          response = await axios.delete(fullPath, {
            headers: requestHeaders,
          });
          break;
        }
        case `POST`: {
          response = await axios.post(fullPath, data, {
            headers: requestHeaders,
          });
          break;
        }
        default: {
          // GET
          response = await axios.get(fullPath, {
            headers: requestHeaders,
          });
          break;
        }
      }
      result = {
        statusCode: response.status,
        data: response.data as T,
      };
    } catch (e: any) {
      return HttpService.resolveError(e);
    }

    return result;
  };

  public static resolveError = (e: {
    message: string;
    response?: {
      data: ApiGenericErrorResponse; // TODO: we can specify another format if needed for example, validation
    };
  }): ApiError => {
    if (axios.isAxiosError(e)) {
      const responseData = e.response?.data;
      if (!responseData) {
        return new ApiError(0, e.message);
      }

      return new ApiError(
        responseData.statusCode,
        responseData.message ?? `Http request failed!`,
        responseData.errorCode ?? null
      );
    } else {
      return new ApiError(0, e.message ?? `Something went wrong.`);
    }
  };
}

export default HttpService;
